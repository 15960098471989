<template>
  <v-row>
    <v-col
      v-bind="columnProperties"
      v-for="type in types"
      :key="type"
    >
      <v-card>
        <v-list-item>
          <v-list-item-title>
            {{`accounting.data.${type}_amount`| t}}
          </v-list-item-title>
          <v-list-item-title class="text-right">
            {{$t('amount.format', { amount: overview[`${type}_amount`] })}}
          </v-list-item-title>
        </v-list-item>

        <v-list-item>
          <v-list-item-title>
            {{`accounting.data.processed_${type}_amount`| t}}
          </v-list-item-title>
          <v-list-item-title class="text-right">
            {{$t('amount.format', { amount: overview[`processed_${type}_amount`] })}}
          </v-list-item-title>
        </v-list-item>

        <v-list-item>
          <v-list-item-title>
            {{`accounting.data.unprocessed_${type}_amount`| t}}
          </v-list-item-title>
          <v-list-item-title class="text-right">
            {{$t('amount.format', { amount: overview[`unprocessed_${type}_amount`] })}}
          </v-list-item-title>
        </v-list-item>
      </v-card>
    </v-col>
  </v-row>
</template>

<script lang="babel" type="text/babel">
export default {
  props: {
    overview: Object,
  },
  computed: {
    types() {
      return [
        'total',
        'revenue',
        'cost',
        'offset',
      ]
    },
    columnProperties() {
      return {
        cols: 12, xl: 3, lg: 3, md: 6, sm: 12, xs: 12,
      }
    }
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>
